<template>
  <v-main class="mt-3 container-prosker">
    <v-row>
      <v-col cols="12" md="3">
        <facet-options
          :update-results="updateResults"
          :url-params="categoryIds"
          :search-text="$route.query.search"
        />
      </v-col>
      <v-col cols="12" md="9">
        <div v-if="loading">
          <v-skeleton-loader
            v-for="index in 5"
            :key="index"
            v-bind="skeletonAttrs"
            type="list-item-avatar, divider, list-item-three-line, card-heading, actions"
          ></v-skeleton-loader>
        </div>
          <div v-else>
        <v-card v-if="usersData.length">
          <v-card-text class="d-md-block d-none">
            <v-row
              class="border-bottom"
              v-for="(item, i) in usersData"
              :key="i"
            >
              <v-col cols="4" md="2" class="text-center">
                <v-avatar width="7rem" height="7rem">
                  <img
                    v-if="!item.profile_img"
                    src="@/assets/images/page-img/avatar.png"
                    class="ounded-circle border border-dark avatar-90"
                    alt="user"
                  />
                  <img
                    v-else
                    :src="item.profile_img"
                    class="rounded-circle border border-dark avatar-90"
                    alt="user"
                  />
                </v-avatar>
                <v-rating
                  v-model="rating"
                  background-color="#59D79D"
                  color="#F2D022"
                  readonly
                  half-increments
                  dense
                  size="1rem"
                ></v-rating>
              </v-col>
              <v-col cols="8" md="10">
                <v-row class="pt-2">
                  <v-col cols="9">
                    <v-row>
                      <span
                        class="
                          mb-0
                          me-1
                          text-prosk
                          font-weight-bold
                          pointer-cursor
                        "
                        @click="goToUserProfile(item)"
                      >
                        {{ item.first_name }} {{ item.last_name }}
                      </span>
                    </v-row>
                    <v-row>
                      <span class="green-prosk">{{ item.count_works_done }} {{ $t('worksDone') }}</span>
                    </v-row>
                    <v-row>
                      <v-chip-group column>
                        <v-chip
                          v-for="(category, i) in item.category_name"
                          :key="i"
                          small
                          class="mx-2 cursor-auto"
                          color="#59D79D"
                          text-color="white"
                        >
                          {{ category }}
                        </v-chip>
                      </v-chip-group>
                    </v-row>
                  </v-col>
                  <v-col cols="3" v-show="currentUser">
                    <v-row class="float-end">
                      <v-btn
                        icon
                        class="pointer-cursor"
                        @click="toggleFavorite(item, false)"
                        v-if="item.is_favorite"
                      >
                        <i class="ri-heart-fill ri-lg green-prosk"></i>
                      </v-btn>
                      <v-btn class="pointer-cursor" icon @click="toggleFavorite(item, true)" v-else>
                        <i class="ri-heart-line ri-lg green-prosk"></i>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="pt-2">
                  <v-col cols="12" md="10">
                    <div class="pr-5">
                      <span>{{ item.description }}</span>
                    </div>
                  </v-col>
                  <v-col cols="12" md="2">
                    <div class="float-md-end mb-2 me-1">
                      <v-row>
                        <v-btn
                          class="bg-green-prosk mb-2 white--text"
                          small
                          @click="createConversation(item.id, item.username)"
                        >
                          <i class="ri-mail-line ri-1x"></i
                          ><span class="ms-1">{{ $t("message") }}</span>
                        </v-btn>
                      </v-row>
                      <v-row v-show="item.isPremiumUser">
                        <v-btn class="bg-green-prosk white--text" small
                               @click="currentUser ? goToUserProfile(item, $options.CALENDAR_TAB) : goToUserProfile(item)">
                          <i class="ri-calendar-2-line ri-1x"></i>
                          <span class="ms-1">{{ $t("schedule") }}</span>
                        </v-btn>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-2 me-1 mb-3">
                  <v-chip-group column>
                    <v-chip
                        v-for="(skill, i) in item.skill_name"
                        :key="i"
                        small
                        outlined
                        class="ma-2 cursor-auto"
                        color="#59D79D"
                    >
                      {{ skill }}
                    </v-chip>
                  </v-chip-group>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="d-block d-md-none">
            <v-row
              class="border-bottom"
              v-for="(item, i) in this.usersData"
              :key="i"
            >
              <v-col cols="3">
                <v-avatar width="4.5rem" height="4.5rem">
                  <img
                    v-if="!item.profile_img"
                    src="@/assets/images/page-img/avatar.png"
                    alt="user"
                    class="avatar-50"
                  />
                  <img
                    v-else
                    :src="item.profile_img"
                    class="rounded-circle avatar-50"
                    alt="user"
                  />
                </v-avatar>
              </v-col>
              <v-col cols="9">
                <v-row>
                  <v-col cols="12" class="mt-5">
                    <v-row>
                      <v-col cols="10" class="p-0">
                        <span
                          class="mb-0 me-1 text-prosk font-weight-bold"
                          @click="goToUserProfile(item)"
                        >
                          {{ item.first_name }} {{ item.last_name }}
                        </span>
                        <v-rating
                          v-model="rating"
                          background-color="#59D79D"
                          color="#F2D022"
                          half-increments
                          readonly
                          dense
                          size="1rem"
                        ></v-rating>
                        <p class="pt-2 ma-0" v-if="item.description">
                          {{ item.description.substr(0, 100) }}{{ item.description.length > 100 ? '...': ''}}
                        </p>
                      </v-col>
                      <v-col cols="2" class="p-0">
                        <v-btn
                          icon
                          @click="toggleFavorite(item, false)"
                          v-if="item.is_favorite"
                        >
                          <i class="ri-heart-fill ri-lg green-prosk"></i>
                        </v-btn>
                        <v-btn icon @click="toggleFavorite(item, true)" v-else>
                          <i class="ri-heart-line ri-lg green-prosk"></i>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                  <span class="green-prosk">{{ item.count_works_done }} {{ $t('worksDone') }}</span>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-chip-group column>
                  <v-chip
                    v-for="(category, i) in item.category_name"
                    :key="i"
                    small
                    class="mx-2"
                    color="#59D79D"
                    text-color="white"
                  >
                    {{ category }}
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-chip-group column>
                  <v-chip
                    v-for="(skill, i) in item.skill_name"
                    :key="i"
                    small
                    class="ma-2"
                    color="#59D79D"
                    outlined
                  >
                    {{ skill }}
                  </v-chip>
                </v-chip-group>
                <div class="d-flex justify-content-between py-5">
                  <v-btn
                    class="bg-green-prosk white--text"
                    small
                    @click="createConversation(item.id, item.username)"
                  >
                    <i class="ri-mail-line ri-1x"></i
                    ><span class="ms-1">{{ $t("message") }}</span>
                  </v-btn>
                  <v-btn v-if="item.isPremiumUser" class="bg-green-prosk white--text" small
                         @click="goToUserProfile(item, $options.CALENDAR_TAB)">
                    <i class="ri-calendar-2-line ri-1x"></i><span class="ms-1">{{ $t("schedule") }}</span>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <div class="text-center" v-if="lastPage !== 1">
            <v-pagination
              v-model="page"
              color="#59D79D"
              :length="lastPage"
              circle
            ></v-pagination>
          </div>
        </v-card>
        <alert :message="$t('noResults')" v-else/>
        </div>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { mapGetters } from 'vuex';
import FacetOptions from '@/components/socialvue/filters/FacetOptions.vue';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import { FEATURE_JOBS, CALENDAR_TAB, USER_TYPE_PROSKER, AES_SECRET } from '@/misc/constants';
import { isFeatureEnabled } from '@/misc/featureFlagService';
import Alert from '@/components/socialvue/alerts/Alert.vue';
import goToUserProfile from '@/components/socialvue/mixins/goToUserProfile';
export default {
  name: 'UserList',
  components: { Alert, FacetOptions },
  mixins: [errorDialog, goToUserProfile],
  data () {
    return {
      page: 1,
      lastPage: 1,
      categoryIds: [],
      searchText: '',
      rating: 5,
      facetOptionsSelected: [],
      usersData: [],
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      loading: true,
      onlyFavorites: false
    };
  },
  created () {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    this.onlyFavorites = (this.$route.params.query === 'favorites');
    this.setImportedConstants();
    this.setSearchParams();
  },
  mounted () {
    this.getUsers();
  },
  watch: {
    facetOptionsSelected: function (value) {
      if (value) {
        this.page = 1;
      }
    },
    page: function (value) {
      if (value) {
        this.getUsers();
      }
    },
    $route (to) {
      if (to.query.search !== this.searchText || to.query.categoryIds !== this.categoryIds.join(',')) {
        this.setSearchParams();
        this.getUsers();
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    setSearchParams () {
      if (this.$route.query.search) {
        this.searchText = this.$route.query.search;
      }
      if (this.$route.query.categoryIds) {
        this.categoryIds = this.$route.query.categoryIds
          .split(',')
          .map((encryptedCatId) => {
            const bytes = CryptoJS.AES.decrypt(encryptedCatId.toString(), this.$options.AES_SECRET);
            const categoryId = bytes.toString(CryptoJS.enc.Utf8);
            return categoryId;
          });
        this.categoryIds.forEach((categoryId) => {
          this.facetOptionsSelected.push({
            field_name: 'categories.id',
            value: categoryId,
            id: null
          });
        });
      }
    },
    setImportedConstants () {
      this.$options.JOBS_ENABLED = isFeatureEnabled(process.env.VUE_APP_RELEASE_VERSION, FEATURE_JOBS);
      this.$options.CALENDAR_TAB = CALENDAR_TAB;
      this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
      this.$options.AES_SECRET = AES_SECRET;
    },
    updateResults (data) {
      this.facetOptionsSelected = data;
      this.getUsers();
    },
    toggleFavorite (user, addToFavorites) {
      if (!this.currentUser) {
        this.showError({
          message: this.$t('mustBeLogged')
        });
      } else {
        this.usersData = this.usersData.map(item => {
          if (item.id === user.id) {
            item.is_favorite = addToFavorites;
          }
          return item;
        });
        const url = `${process.env.VUE_APP_BASE_URL}/api/favorites`;
        const data = addToFavorites ? {
                  favorite_user_id: user.id,
                  user_id: this.currentUser.id
              } : { favorite_user_id: user.id };
        const method = addToFavorites ? 'post' : 'delete';
        axios({ method, url, data })
        .then(() => {})
        .catch((error) => {
          this.usersData = this.usersData.map(item => {
            if (item.id === user.id) {
              item.is_favorite = !addToFavorites;
            }
            return item;
          });
          this.showError(error);
        });
      }
    },
    getConversation (recipientId, senderId, callback) {
      const metadata = [recipientId, senderId].sort((a, b) => {
        if (a >= b) {
          return 1;
        }
        return -1;
      }).join(',');
      // TODO - improve conversation filtering!
      const url = `${process.env.VUE_APP_BASE_URL}/api/conversations?search=${metadata}`;
      axios
        .get(url)
        .then((response) => {
          if (!response.data.data.length) {
            throw new Error(this.$t('errorLoadingConversation'));
          } else {
            callback(response.data.data[0].id);
          }
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    createConversation (userId, userName) {
      if (!this.currentUser) {
        this.$swal.fire({ title: this.$t('accessContentNeedLogIn') });
        this.$router.push({ name: 'auth1.sign-in1' });
      } else {
        const url = `${process.env.VUE_APP_BASE_URL}/api/conversations`;
        this.name = userName;
        this.userIds = [this.currentUser.id, userId];
        const data = {
          name: this.name,
          user_ids: this.userIds
        };
        axios
          .post(url, data)
          .then((res) => {
            this.$router.push({
              name: 'app.chat',
              params: { conversationId: res.data.data[0].id.toString() }
            });
          })
          .catch((error) => {
            if (error.response.data.errors.user_ids && error.response.data.errors.user_ids[0] === 'conversationAlreadyCreated') {
              this.getConversation(
                userId,
                this.currentUser.id,
                (conversationId) => {
                  this.$router.push({
                    name: 'app.chat',
                    params: { conversationId: conversationId.toString() }
                  });
                }
              )
            } else {
              this.showError(error);
            }
          });
      }
    },
    getURL () {
      const urlFilters = {};
      let filterQuery = 'filters=';
      this.facetOptionsSelected.forEach((facetOption) => {
        if (urlFilters[facetOption.field_name]) {
          urlFilters[facetOption.field_name].push(facetOption.value);
        } else {
          urlFilters[facetOption.field_name] = [facetOption.value];
        }
      });

      if (JSON.stringify(urlFilters) !== '{}') {
        for (let prop in urlFilters) {
          filterQuery += `${prop}=${urlFilters[prop].join('|')}|,`;
        }
      }

      // by default, search list does not return clients nor users without profile image
      filterQuery += `first_name=notNull,last_name=notNull,profile_img=notNull,type_user>=${this.$options.USER_TYPE_PROSKER}`;

      if (this.searchText) {
        return `${process.env.VUE_APP_BASE_URL}/api/users?search=${this.searchText}&${filterQuery}&page=${this.page}`;
      } else {
        return `${process.env.VUE_APP_BASE_URL}/api/users?${filterQuery}&page=${this.page}`;
      }
    },
    getUsers () {
      this.usersData = [];
      this.loading = true;
      let urls = [this.getURL()];

      if (this.currentUser) {
        urls.push(`${process.env.VUE_APP_BASE_URL}/api/favorites?per_page=all&filters=user_id=${this.currentUser.id}`);
      }

      const requests = urls.map((url) => axios.get(url));

      axios
      .all(requests)
      .then((responses) => {
        responses.forEach((response, index) => {
          const data = response.data;
          if (index === 0) {
            // get users response
            this.lastPage = data.last_page;
            this.usersData = this.currentUser
            ? data.data.filter((user) => user.id !== this.currentUser.id && user.categories.length > 0)
            : data.data.filter((user) => user.categories.length > 0);
          } else {
            // get favorites response
            const favorites = data.data;
            this.usersData = this.usersData.map(user => {
              user.is_favorite = !!favorites.find(item => item.favorite_user_id === user.id);
              user.isPremiumUser = user.active_subscription.filter(item => item.name === 'Premium' || item.name === 'Free Trial').length > 0;
              return user;
            });
          }
        });
        this.loading = false;
      })
      .catch((error) => {
        this.showError(error);
        this.loading = false;
      });
    }
  }
};
</script>
